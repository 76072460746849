export const publicStoragePathPattern = /^(thumbs|scenarios|audio|organizations)/;

export function getFileUrl(path) {
  return `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_STORAGE_BUCKET}/o/${encodeURIComponent(
    path
  )}?alt=media`;
}

export const getPublicUrl = filePath =>
  filePath?.match(publicStoragePathPattern)
    ? "https://firebasestorage.googleapis.com/v0/b/" +
      process.env.REACT_APP_STORAGE_BUCKET +
      "/o/" +
      encodeURIComponent(filePath) +
      "?alt=media"
    : null;

export const getThumbUrl = (filePath, size) =>
  filePath?.match(publicStoragePathPattern)
    ? "https://firebasestorage.googleapis.com/v0/b/" +
      process.env.REACT_APP_STORAGE_BUCKET +
      "/o/" +
      encodeURIComponent(filePath + `.resized.${size}px.webp`) +
      "?alt=media"
    : null;

export const getSignedOrPublicUrl = eventObj => eventObj.url || getPublicUrl(eventObj.audio) || null;

export const getStorageUrl = filePath =>
  "https://storage.cloud.google.com/" + process.env.REACT_APP_STORAGE_BUCKET + filePath;

export const getAdminConsoleUrl = filePath =>
  "https://console.cloud.google.com/storage/browser/" + process.env.REACT_APP_STORAGE_BUCKET + filePath;

const rtdbId = process.env.REACT_APP_REALTIME_DB.split(".")[0].replace("https://", "");

export const getRTDBConsoleUrl = path =>
  "https://console.firebase.google.com/u/0/project/" +
  process.env.REACT_APP_PROJECT_ID +
  "/database/" +
  rtdbId +
  "/data/" +
  encodeURIComponent(path);
