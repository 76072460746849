import React, { useState, useEffect } from "react";
import { ReactComponent as Conversation } from "../../assets/Conversation.svg";
import { USER_GUIDE_BETA_ENG, PRIVACY_POLICY } from "../../constants/urls";
import { ConsentContainer } from "../../screens/SignIn/style";
import { signOut } from "../SignOut";
import { compose } from "../../helpFunctions/general";
import { withFirebase } from "../Firebase";
import { FrInput, FrLabel, FrButton, FrFinePrint } from "../../components/DesignSystem/style.js";
import Loading from "../../components/Loading";
import styled from "styled-components";
import { smoothIn } from "../../styles/keyframes";
import { SelectorContainer } from "../../components/Selector";
import AvatarSelector from "../AvatarSelector";
import * as Sentry from "@sentry/react";

const UserGuide = () => {
  const [firstVisit, setFirstVisit] = useState(false);
  useEffect(() => {
    const hasVisited = JSON.parse(window.localStorage.getItem("hasVisited"));
    if (!hasVisited) {
      setFirstVisit(true);
    }
  }, []);

  const onClick = () => {
    window.localStorage.setItem("hasVisited", JSON.stringify("true"));
    setFirstVisit(false);
  };

  return (
    <>
      {firstVisit && (
        <PopupContainer>
          <GuidePopupWrapper>
            <Conversation />
            <p>Thanks for joining the Fictive Beta! We strongly recommend reading our guide before continuing.</p>
            <div>
              <a href={USER_GUIDE_BETA_ENG} target="_blank" rel="noopener noreferrer">
                Open guide
              </a>
              <button onClick={() => onClick()}>Continue</button>
            </div>
          </GuidePopupWrapper>
        </PopupContainer>
      )}
    </>
  );
};

export default UserGuide;

const InnerAddUserToDB = ({ authUser, doRegisterUser, firebase }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState(authUser && authUser.email ? authUser.email : "");
  const [avatarId, setAvatarId] = useState("");
  const [createdAvatars, setCreatedAvatars] = useState([]);
  const [termsConsent, setTermsConsent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isInvalidSignUp = email === "" || displayName === "" || avatarId.id === "" || termsConsent === false;

  const save = () => {
    setLoading(true);
    let tempUserObj = {
      email: email,
      avatar: avatarId,
      uid: authUser.userId,
      // Only RPM created avatars use the avatarName field when registering,
      // it will be used to populate user.createdAvatars
      avatarName: createdAvatars.length ? createdAvatars[0].name : null,
      displayName: displayName,
      termsConsent: termsConsent,
      update: true,
    };
    doRegisterUser(tempUserObj)
      .then(res => {
        setLoading(false);
        if (!res.data && !res.data.userDbRecord) {
          setError("Something went wrong, user registration failed: Please contact your admin to solve this issue");
        } else {
          window.location.reload();
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        console.error(err);
        setError("Something went wrong, user registration failed: Please contact your admin to solve this issue");
        setLoading(false);
      });
  };
  const changedAvatarCallback = (id, createdAvatars) => {
    setAvatarId(id);
    setCreatedAvatars([...createdAvatars]);
  };

  return (
    <PopupContainer>
      <GeneralPopupWrapper>
        {loading ? (
          <Loading loadingText={"Creating user..."} width={"80px"} containerHeight={"400px"} containerWidth={"500px"} />
        ) : (
          <>
            <h2 style={{ padding: "0 0 5px" }}>Create user</h2>
            <p>Please fill in the form below to complete the sign up process!</p>
            <div>
              <FrLabel padding={"0 10px"} margin={"20px 0 5px"}>
                Avatar
              </FrLabel>
              <SelectorContainer fullWidth={"70%"} background={"var(--grey-5)"} margin={"5px 0 0"}>
                <AvatarSelector
                  avatarId={avatarId}
                  knownAvatars={createdAvatars}
                  changedAvatarCallback={changedAvatarCallback}
                />
              </SelectorContainer>
              <FrLabel padding={"0 10px"} margin={"15px 0 5px"}>
                Display Name
              </FrLabel>
              <FrInput
                maxLength="40"
                placeholder="Display Name"
                type="text"
                dataCy=""
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
                background={"var(--grey-5)"}
              ></FrInput>
              <FrLabel padding={"0 10px"} margin={"15px 0 5px"}>
                Email
              </FrLabel>
              <FrInput
                disabled
                maxLength="40"
                placeholder="Email"
                type="text"
                dataCy=""
                value={email}
                onChange={e => setEmail(e.target.value)}
                background={"var(--grey-5)"}
              ></FrInput>
              <ConsentContainer>
                <input type="checkbox" checked={termsConsent} onChange={() => setTermsConsent(!termsConsent)}></input>
                <p>
                  I consent to sharing some personal data, receiving non-marketing email and storing cookies from this
                  service.
                  <br />
                  <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                    Read our privacy policy for details!
                  </a>
                </p>
              </ConsentContainer>
            </div>
            <FrButton disabled={isInvalidSignUp} width={"100%"} margin={"30px 0 0"} onClick={() => save()}>
              Create user
            </FrButton>
            {error && <div style={{ marginTop: "20px", color: "red" }}>{error}</div>}
            <div>
              <FrButton background={"var(--grey-1)"} margin={"15px 0 0"} onClick={() => signOut(null, firebase)}>
                Cancel
              </FrButton>
            </div>
          </>
        )}
      </GeneralPopupWrapper>
    </PopupContainer>
  );
};
export const AddUserToDB = compose(withFirebase)(InnerAddUserToDB);

export const PrivacyPolicy = ({ authUser, firebase, history, dispatch }) => {
  const saveConsent = () => {
    authUser.termsConsent = true;
    firebase.getUser(authUser.userId).update({ termsConsent: true });
    authUser.termsConsent = true;
    if (dispatch) dispatch({ type: "SET_USER", user: authUser });
  };

  return (
    <PopupContainer>
      <GuidePopupWrapper style={{ minWidth: "300px", minHeight: "300px" }}>
        <h2>Privacy Notice</h2>
        <p>
          I consent to essential cookies{authUser && authUser.email && ", receiving service email"} and sharing my
          recordings when talking to the AI.
          <br />
          <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            Read our privacy policy for details!
          </a>
        </p>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <FrButton style={{ background: "var(--key-color-2)" }} onClick={() => saveConsent()}>
            Accept
          </FrButton>
          <FrButton onClick={() => signOut(history, firebase)}>Decline</FrButton>
        </div>
        <FrFinePrint>Declining will sign you out of the app.</FrFinePrint>
      </GuidePopupWrapper>
    </PopupContainer>
  );
};

export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(102 102 102 / 70%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation: ${smoothIn} 0.4s;
`;

export const GeneralPopupWrapper = styled.div`
  background: white;
  padding: 30px 40px;
  border-radius: 20px;
  min-width: 300px;
  max-width: 600px;
`;

export const GuidePopupWrapper = styled.div`
  width: 50%;
  min-width: 300px;
  max-width: 600px;
  padding: 20px 30px;
  border-radius: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  svg {
    width: 200px;
    height: auto;
  }

  h5 {
    font-size: 14px;
    color: var(--grey-1);
  }

  p {
    padding: 20px 0;
    line-height: 1.4;
    text-align: center;
  }

  div {
    a {
      margin: 10px;
      background: var(--key-color-2-gradient);
      color: white;
      padding: 8px 30px;
      border-radius: 20px;
      font-weight: 600;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        box-shadow: "0px 0px 5px var(--key-color-2)";
      }
    }

    button {
      margin: 0 5px;
      background: var(--grey-3);
      color: white;
      padding: 8px 30px;
      border-radius: 20px;
      font-weight: 600;
      cursor: pointer;
      border: none;
      outline: none;
      font-size: 16px;

      &:hover {
        box-shadow: "0px 0px 5px var(--key-color-2)";
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
`;
