import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/functions";
import * as Sentry from "@sentry/react";
import { VendorMicrosoft } from "@styled-icons/typicons/VendorMicrosoft";
import { Google } from "@styled-icons/boxicons-logos/Google";
import { LockOpen } from "@styled-icons/boxicons-solid/LockOpen";

import { EmailAuthProvider } from "firebase/auth";

var config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_REALTIME_DB,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor() {
    let fireApp = app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.functions = fireApp.functions(process.env.REACT_APP_REGION);
    this.auth.setPersistence("local");

    this.authProviders = {
      microsoft: {
        auth: new app.auth.OAuthProvider("microsoft.com"),
        displayName: "Microsoft",
        icon: VendorMicrosoft,
        showInSignin: true,
      },
      google: {
        auth: new app.auth.GoogleAuthProvider(),
        displayName: "Google",
        icon: Google,
        showInSignin: true,
      },
      "saml.jumpcloud-saml": {
        auth: new app.auth.SAMLAuthProvider("saml.jumpcloud-saml"),
        displayName: "Jumpcloud",
        icon: LockOpen,
        showInSignin: false,
      },
      "saml.fictive-azure-test": {
        auth: new app.auth.SAMLAuthProvider("saml.fictive-azure-test"),
        displayName: "Azure SAML Test",
        icon: LockOpen,
        showInSignin: false,
        logoutRedirect: true,
      },
      "saml.seb-azure-prod": {
        auth: new app.auth.SAMLAuthProvider("saml.seb-azure-prod"),
        displayName: "SEB Active Directory",
        icon: LockOpen,
        emailMatch: new RegExp(/.*@seb\.se$/),
        showInSignin: false,
        logoutRedirect: true,
      },
      anon: {
        auth: null,
        displayName: "Anonymous",
        icon: LockOpen,
        emailMatch: null,
        showInSignin: false,
        logoutRedirect: true,
      },
    };
    this.copyScenarioFiles = this.functions.httpsCallable("assets-copyScenarioFiles");
    this.generateSpeech = this.functions.httpsCallable("assets-generateSpeech");
    this.generateSpeechByPath = this.functions.httpsCallable("assets-generateSpeechByPath");
    this.clearDemoSessions = this.functions.httpsCallable("stats-clearDemoSessions");
    this.registerUser = this.functions.httpsCallable("auth-registerUser");
    this.getUserDetails = this.functions.httpsCallable("auth-getUserDetails");
    this.fetchSuggestedLines = this.functions.httpsCallable("assets-fetchSuggestedLines");
    this.generatePin = this.functions.httpsCallable("auth-generatePin");
    this.trainingContentEvaluation = this.functions.httpsCallable("assets-textSumEvaluation", { timeout: 300000 });
    this.trainingContent = this.functions.httpsCallable("assets-textSum", { timeout: 120000 });
    this.ensureSignedSessionUrls = this.functions.httpsCallable("auth-ensureSignedSessionUrls");
    this.createInvite = this.functions.httpsCallable("auth-createInvite");
  }

  componentWillUnmount() {
    this.getUser.off();
  }
  //Cloud functions

  doGeneratePin = data => this.generatePin(data);

  doFetchSuggestedLines = data => this.fetchSuggestedLines(data);

  doClearDemoSessions = data => this.clearDemoSessions(data);

  doCopyScenarioFiles = inputObj => this.copyScenarioFiles(inputObj);

  doGenerateSpeech = data => this.generateSpeech(data);

  doGenerateSpeechByPath = data => this.generateSpeechByPath(data);

  doRegisterUser = data => this.registerUser(data);

  doGetUserDetails = uid => this.getUserDetails({ uid });

  doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification();

  // // link auth provider to user account
  // doLinkWithPopUp = () => this.auth.currentUser.linkWithPopup(this.provider);

  //Login user
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSendSignInLinkToEmail = (email, actionCodeSettings) => this.auth.sendSignInLinkToEmail(email, actionCodeSettings);

  doSignInAnonymously = () => this.auth.signInAnonymously();

  doSignInWithProvider = provider =>
    this.authProviders[provider]
      ? this.auth.signInWithPopup(this.authProviders[provider].auth)
      : new Error(`Provider ${provider} not configured`);

  isSignInWithEmailLink = location => this.auth.isSignInWithEmailLink(location);

  isEmailPasswordProvider = provider => provider === EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD;
  isEmailLinkProvider = provider => provider === EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD;

  doFetchSignInMethodsForEmail = email => this.auth.fetchSignInMethodsForEmail(email);

  doSignInWithCustomToken = token => this.auth.signInWithCustomToken(token);

  doSignInWithEmailLink = (email, location) => this.auth.signInWithEmailLink(email, location);
  //Sign out user
  doSignOut = () => this.auth.signOut();

  //Reset user password
  doPasswordReset = (email, actionCodeSettings) => this.auth.sendPasswordResetEmail(email, actionCodeSettings);

  //Update user password
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  getIgnoreError = async path => {
    try {
      return (await this.db.ref(path).once("value")).val();
    } catch (err) {
      return null;
    }
  };

  authenticatedFetch = (url, options = {}) => {
    return this.getIdToken().then(token => {
      if (!options.headers) options.headers = {};
      options.headers["Authorization"] = `Bearer ${token}`;
      return fetch(url, options);
    });
  };

  getIdToken = () => this.auth.currentUser.getIdToken(false);

  refreshToken = () => this.auth.currentUser.getIdToken(true);

  reloadUser = () => this.auth.currentUser.reload();

  // *** Auth API ***
  onAuthUserListener = (loginCb, logoutCb) =>
    this.auth.onAuthStateChanged(async authUser => {
      if (!authUser)
        // logged out
        return logoutCb();

      let claims = {};
      try {
        const idTokenResult = await authUser.getIdTokenResult();
        claims = idTokenResult?.claims || {};
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
        return logoutCb();
      }

      let userId = authUser.uid;
      let dbUser = await this.getIgnoreError(`/users/${userId}`);

      // No user found in db
      if (!dbUser) {
        dbUser = {
          email: authUser.email,
          displayName: (authUser.email && authUser.email.match(/^([^@]*)@/)[1]) || "Anonymous",
          termsConsent: false,
        };
      }

      loginCb({
        ...dbUser,
        userId,
        claims,
        refreshToken: authUser.refreshToken,
      });
    });

  // Function to fetch data from a list of paths
  fetchFirebasePaths = async (prefix, ids) => {
    // Map each path to a promise that fetches data from that path
    const fetchPromises = ids.map(id => {
      const dataRef = this.db.ref(prefix + id);
      return dataRef.get().then(snapshot => {
        if (snapshot.exists()) {
          return [id, snapshot.val()];
        } else {
          return [id, null];
        }
      });
    });

    // Wait for all promises to resolve
    const results = await Promise.all(fetchPromises);

    return results.reduce((acc, [id, data]) => {
      acc[id] = data;
      return acc;
    }, {});
  };

  // *** NEW STORAGE STRUCTURE ***

  //Scenario

  scenarioCoverImages = () => this.storage.ref("thumbs/scenarioCoverImages");

  scenarioGetSlide = (scenarioId, fileName) => this.storage.ref(`scenarios/${scenarioId}/slides/${fileName}`);

  scenarioGetDoc = (scenarioId, filename) => this.storage.ref(`scenarios/${scenarioId}/docs/${filename}`);

  scenarioStats = id => this.db.ref(`/stats/scenarios/${id}`);

  getScenarios = () => this.db.ref("/scenarios");

  getScenario = id => this.db.ref(`/scenarios/${id}`);

  scenarioModeExchanges = (id, mode) => this.db.ref(`/scenarios/${id}/modes/${mode}/exchanges`);

  scenarioModeExchange = (id, mode, exchangeId) =>
    this.db.ref(`/scenarios/${id}/modes/${mode}/exchanges/${exchangeId}`);

  genericAudioExample = (scenarioId, locale, gender, voiceName) =>
    this.db.ref(
      `/scenarios/${scenarioId}/modes/generic/exchanges/utter-greet/lines/0/variants/0/audio/${locale}/${gender}/${voiceName}`
    );

  // Organizations

  organizationLogo = (organizationId, name) => this.storage.ref(`organizations/${organizationId}/media/${name}`);

  //Sessions

  uploadRef = sessionId => this.storage.ref(`/sessions/${sessionId}/videoRecording.webm`);

  sessions = () => this.db.ref("/sessions");

  session = sessionId => this.db.ref(`/sessions/${sessionId}`);

  sessionEvents = sessionId => this.db.ref(`/sessions/${sessionId}/events`);

  sessionSummary = (organizationId, userId, sessionId) =>
    this.db.ref(`/analytics/${organizationId}/${userId}/${sessionId}/summary`);

  //Organizations

  getOrganizations = () => this.db.ref("/organizations");

  getOrganization = id => this.db.ref(`/organizations/${id}`);

  getSpecificOrganizations = ids => this.fetchFirebasePaths("/organizations/", ids);

  //Users

  getUsers = () => this.db.ref("/users");

  getUser = id => this.db.ref(`/users/${id}`);

  //Notes

  notes = (userId, scenarioId, voiceLineId) =>
    this.db.ref(`/users/${userId}/scenarios/${scenarioId}/exchanges/${voiceLineId}/notes`);

  note = (userId, scenarioId, voiceLineId, noteId) =>
    this.db.ref(`/users/${userId}/scenarios/${scenarioId}/exchanges/${voiceLineId}/notes/${noteId}`);

  // Voices, etc

  allVoices = () => this.db.ref("/voices");

  getVoice = id => this.db.ref(`/voices/${id}`);

  intentLookup = () => this.db.ref(`/lookup/intents`);

  coachTranslations = locale => this.db.ref(`/translations/${locale}/evaluation`);

  getInvite = id => this.db.ref(`/invites/${id}`);
  doCreateInvite = value => this.createInvite(value).then(result => result.data);
}

export default Firebase;
