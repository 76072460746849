import { useContext } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "../../helpFunctions/general";
import AuthUserContext from "./context";
import { DataStoreContext } from "../DataStore/DataStoreContext";
import { withFirebase } from "../Firebase";
import { Lock } from "@styled-icons/boxicons-regular/Lock";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import * as ROUTES from "../../constants/routes";

const withAuthorization = (condition, showNoStatus) => Component => {
  const WithAuthorization = props => {
    const authContext = useContext(AuthUserContext);
    let stateContext = useContext(DataStoreContext);
    // withAuth is used in DialogueApp, which does not have DataStoreContext as parent
    // therefore, state is undefined. The only reason conditions need this is to check
    // permissions against an organization ID, so we instead use the authUsers organization there
    // (a little hacky)
    if (!stateContext) {
      stateContext = { selectedOrg: authContext?.authUser?.organization };
    }

    return authContext && condition(authContext.authUser, stateContext) ? (
      <Component {...props} authUser={authContext.authUser} />
    ) : (
      <>{!showNoStatus ? <AuthorizationFailed authContext={authContext} /> : null}</>
    );
  };

  return compose(withRouter, withFirebase)(WithAuthorization);
};

const AuthorizationFailed = ({ authContext }) => {
  return (
    <>
      {/* Not authorized but signed in, render error */}
      {authContext && !authContext.loading && authContext.authUser && (
        <PermissionDenied>
          <Lock />
          <h2>You don't have permission to view this page.</h2>
          <h2 style={{ marginTop: "10px" }}>
            Return to <Link to={ROUTES.HOME}>Home page</Link>
          </h2>
        </PermissionDenied>
      )}
      {/* Not authorized because NOT signed in, REDIRECT */}
      {authContext && !authContext.loading && !authContext.authUser && (
        <Redirect
          to={{
            pathname: ROUTES.SIGN_IN,
            search: window.location.search,
            state: {
              nextUrl:
                window.location.pathname === "/signin" ? null : window.location.pathname + window.location.search,
            },
          }}
        />
      )}
    </>
  );
};

export default withAuthorization;

const PermissionDenied = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 1rem;
  margin-top: 6rem;
  // height: 100%;

  svg {
    width: 200px;
    height: 200px;
    color: var();
  }

  h2 {
    font-size: 1rem;
    text-align: center;
  }

  a {
    color: var(--key-color-2);
    text-decoration: underline;
  }
`;
