import React from "react";
import { withFirebase } from "../Firebase";
import { LogOutStyle } from "./style";
import { LogOutCircle } from "@styled-icons/boxicons-regular/LogOutCircle";
import ReactTooltip from "react-tooltip";

export const signOut = (history, firebase) => {
  window.localStorage.clear();
  if (!firebase.auth.currentUser) return history?.push({ pathname: "/signin", state: null });
  firebase.auth.currentUser.getIdTokenResult().then(res => {
    const provider = res.signInProvider;
    const nameId = firebase.auth.currentUser.email;
    if (provider && nameId && firebase.authProviders[provider]?.logoutRedirect) {
      firebase
        .doSignOut()
        .then(() =>
          window.location.replace(
            `/signout?redirect=true&idp=${encodeURIComponent(provider)}&nameId=${encodeURIComponent(nameId)}`
          )
        );
    } else {
      // By loading the URL from scratch, we will clear all Firebase listeners and get a clean state
      // Otherwise we would need to find and clean out all listeners to Firebase data that may depend on authUser to be permitted
      // This also ensures that the DataStore is reset, currently haven't found a clean way to do this by listening to authUser becoming null
      firebase.doSignOut().then(() => window.location.replace("/signin"));
      // firebase.doSignOut(); // This doesn't create a visual reload of the page but has above issues
    }
  });
};

// Show tooltips needs only to be true in desktop navigation
const SignOutButton = ({ dataCy, history, firebase, showTooltip = false }) => {
  return (
    <LogOutStyle
      onClick={() => signOut(history, firebase)}
      data-cy={dataCy}
      data-for="sign-out"
      data-tip={"<h5>Sign out</h5>"}
    >
      <LogOutCircle />
      <p>Sign out</p>
      {showTooltip && <ReactTooltip place="right" multiline={true} html={true} id="sign-out"></ReactTooltip>}
    </LogOutStyle>
  );
};

export default withFirebase(SignOutButton);
