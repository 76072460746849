import React, { useState, useEffect, useRef } from "react";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import { startSpan, endSpan, getQueryArgs } from "../../helpFunctions/general";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as ROUTES from "../../constants/routes";

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const [authUser, setAuthUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [signingIn, setSigningIn] = useState(false);

    const spanRef = useRef(null);
    const history = useHistory();

    const updateUser = user => {
      setAuthUser(prevAuthUser => ({ ...prevAuthUser, ...user }));
    };

    useEffect(() => {
      startSpan({ spanRef: spanRef, op: "db", description: "Firebase Auth" });
      // Start listening to firebase auth updates (once on mount)
      const listener = props.firebase.onAuthUserListener(
        authUser => {
          setAuthUser(authUser);
          setLoading(false);
          endSpan(spanRef);
          Sentry.setUser(
            authUser && authUser.userId
              ? {
                  id: authUser.userId,
                  memberOf: authUser.claims?.memberOf,
                  emailVerified: authUser.claims?.email_verified,
                }
              : null
          );
          console.log(`Authenticated as ${authUser?.userId}`);
        },
        () => {
          endSpan(spanRef);
          Sentry.setUser(null);
          setAuthUser(null);
          setLoading(false);
        }
      );

      return () => {
        listener();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.firebase]);

    useEffect(() => {
      // If invite query parameter at initial load, redirect
      const queryArgs = getQueryArgs(window.location);
      const invite = queryArgs?.get("invite");
      if (invite) {
        history.push({
          pathname: ROUTES.SIGN_IN,
          search: window.location.search,
          // nextUrl includes the invite query parameter but it will be removed at signin page
          state: { nextUrl: `${window.location.pathname}${window.location.search}` },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <AuthUserContext.Provider value={{ authUser, loading, signingIn, updateUser, setSigningIn }}>
        <Component {...props} authUser={loading || signingIn ? null : authUser} />
      </AuthUserContext.Provider>
    );
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
