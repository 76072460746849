import { isNil } from "lodash";

export const formatSessionLength = (sessionLen, fallbackValue = "-") => {
  if (!sessionLen || sessionLen <= 0) return fallbackValue;
  let seconds = Math.floor((sessionLen / 1000) % 60);
  let minutes = Math.floor((sessionLen / (1000 * 60)) % 60);
  let hours = Math.floor((sessionLen / (1000 * 60 * 60)) % 24);

  return seconds + minutes + hours > 0
    ? `${hours >= 1 ? hours + "h" : ""} ${minutes >= 1 ? minutes + "m" : ""} ${seconds >= 1 ? seconds + "s" : ""}`
    : "-";
};

export const numCountToRows = (countObject, prefix = "", suffix = "", max = 5) => {
  let rows = [...Array(max).keys()]
    .sort((a, b) => b - a)
    .map(i => [prefix + (i + 1) + suffix, countObject?.[`no${i + 1}`] || 0]);
  return rows;
};

export const decileCountToRows = (countObject, prefix = "", suffix = "") => {
  let rows = Object.keys(countObject || {})
    .sort((a, b) => parseInt(b, 10) - parseInt(a, 10))
    .map(key => [prefix + key + suffix, countObject?.[key] || 0]);
  return rows;
};

export const decileCountTo5Rows = (countObject, prefix = "", suffix = "") => {
  const aggregated = {};

  Object.entries(countObject || {}).forEach(([key, value]) => {
    // Determine the range the current decile belongs to
    let base = parseInt(key, 10);
    if (base === 100) base = 90; // Merge in 100% results with 80-100%
    const rangeKey = `${Math.floor(base / 20) * 20}-${Math.floor(base / 20) * 20 + 20}%`;

    // Initialize or update the count for the range
    if (!aggregated[rangeKey]) {
      aggregated[rangeKey] = value;
    } else {
      aggregated[rangeKey] += value;
    }
  });

  let rows = Object.keys(aggregated)
    .sort()
    .map(key => [prefix + key + suffix, aggregated?.[key] || 0]);
  return rows;
};

export const formatPercentage = (number, fallbackValue = "-") =>
  isNil(number) ? fallbackValue : `${Math.round(100 * (number || 0))}%`;

export const formatRating = (number, fallbackValue = "-") =>
  isNil(number) ? fallbackValue : Math.round(((number || 0) + Number.EPSILON) * 100) / 100;

export const formatSessionDate = (sessionDate, fallbackValue = "-") => {
  if (sessionDate && sessionDate.length !== "") {
    let newDate = new Date(sessionDate).toString();
    let dateArr = newDate.slice(0, 21).split(" ");
    return `${dateArr[2]} ${dateArr[1]} ${dateArr[3]} ${dateArr[4]}`;
  } else {
    return fallbackValue;
  }
};

export const sortableDateFormat = date => {
  if (date) {
    let dateFormat = new Date(date);
    return dateFormat;
  } else {
    return null;
  }
};

export const capitalizeFirstLetter = name => {
  if (name && name.length > 0) {
    let tempName = name.toLowerCase();
    return tempName.charAt(0).toUpperCase() + tempName.slice(1);
  } else {
    return "";
  }
};

export const hardWrap = ({ str, maxLength = 80, lineBreak = "<br />" }) => {
  const words = str.split(" ");
  let currentLineLength = 0;
  let result = "";

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    currentLineLength += word.length + 1;

    if (currentLineLength > maxLength) {
      result += lineBreak;
      currentLineLength = word.length + 1;
    }

    result += word + " ";
  }

  return result.trim();
};

export const averageSessionLength = statsObject => {
  return statsObject?.sessionsStarted > 0 ? (statsObject?.playtimeTotal || 0) / statsObject?.sessionsStarted : 0;
};

export const mapSessionsData = (sessions, authUserId) => {
  if (sessions && sessions.length >= 1) {
    const mappedSessions = sessions.map(session => {
      return {
        scenarioDisplayName: capitalizeFirstLetter(session.scenarioDisplayName),
        date: sortableDateFormat(session.created),
        sessionLength: session.sessionLength ? session.sessionLength : 0,
        userId: session.playerId,
        playerDisplayName: capitalizeFirstLetter(session.playerDisplayName),
        sessionId: session.id,
        model: session.model ? session.model : "unknown",
        organizationId: session.organizationId,
        assessmentSession: session.assessmentSession ? true : false,
        status: session.status ? session.status : null,
        hideScore:
          session.auth !== true &&
          session.playerId === authUserId &&
          session.assessmentSession &&
          session.status !== "rated"
            ? true
            : false,
        auth: session.auth,
        skill: (100 * (session?.stats?.score?.averageScore || session?.stats?.goal?.averageScore || 0)).toFixed(0),
        goalStats: session.stats && session.stats.goal,
        scoreStats: session.stats && session.stats.score,
      };
    });
    return mappedSessions;
  } else {
    return null;
  }
};

export const appendSessionEventTransactor = eventToAdd => {
  return sessionEvents => {
    if (!sessionEvents) {
      return [eventToAdd];
    } else {
      return [...sessionEvents, eventToAdd];
    }
  };
};

export const addEventToSession = (firebase, sessionId, event) => {
  if (!sessionId) throw new Error("No session ID found to save video");

  return firebase.sessionEvents(sessionId).transaction(appendSessionEventTransactor(event));
};
