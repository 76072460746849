import React, { useState } from "react";
import { MobileNavWrapper, SliderBlanket, SliderContainer, HamburgerMenuButton, StyledNavContainer } from "./style";
import { HamMenu } from "../../styles/icons";
import SignOutButton from "../SignOut";
import UserAvatarLink from "./components/UserAvatarLink";
import NavList from "./components/NavList";
import FictiveLogo from "../../assets/fictive_logo_key_color.svg?url";

const MobileNavigation = props => {
  const [sideMenuShow, setSideMenuShow] = useState(false);

  const menuClick = () => {
    sideMenuShow ? setSideMenuShow(false) : setSideMenuShow(true);
  };

  const maybeRenderBlanket = () => {
    if (sideMenuShow) {
      return <SliderBlanket onClick={menuClick}></SliderBlanket>;
    }
  };

  const handleClick = href => {
    props.history.push(href);
    menuClick();
  };

  return (
    <>
      {props.authUser && (
        <MobileNavWrapper data-cy="navigationHeader">
          <HamburgerMenuButton data-cy="tempBtn" onClick={menuClick}>
            <HamMenu />
          </HamburgerMenuButton>
          <SliderContainer data-cy="sidemenu" open={sideMenuShow}>
            <StyledNavContainer>
              <img style={{ marginTop: "10px", width: "50px" }} src={FictiveLogo} alt="fictive reality logo" />
              <NavList
                handleClick={handleClick}
                authUser={props.authUser}
                currentPath={props.history.location.pathname}
              />
              <UserAvatarLink authUser={props.authUser} />
              <SignOutButton dataCy={"mainLogout"} history={props.history} />
            </StyledNavContainer>
          </SliderContainer>
          {maybeRenderBlanket()}
        </MobileNavWrapper>
      )}
    </>
  );
};

export default MobileNavigation;
