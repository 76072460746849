import React, { useEffect, useContext } from "react";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import { DesktopNavWrapper, StyledNavContainer } from "./style";
import SignOutButton from "../SignOut";
import { withRouter } from "react-router-dom";
import MobileNavigation from "./mobileNavigation";
import UserAvatarLink from "./components/UserAvatarLink";
import NavList from "./components/NavList";
import { DataStoreContext } from "../DataStore/DataStoreContext";
import FictiveLogo from "../../assets/fictive_logo_key_color.svg?url";
import { capitalizeFirstLetter } from "../../helpFunctions/session";

const Navigation = props => {
  const { state } = useContext(DataStoreContext);

  return (
    <div>
      <AuthUserContext.Consumer>
        {authContext =>
          authContext?.authUser &&
          props.location.pathname !== ROUTES.SIGN_IN &&
          !props.location.pathname.includes(ROUTES.DIALOGUE_APP) && (
            <>
              <NavigationAuth
                location={props.location.pathname}
                authUser={authContext.authUser}
                history={props.history}
                organization={state.organization}
              />
              <MobileNavigation authUser={authContext.authUser} history={props.history} />
            </>
          )
        }
      </AuthUserContext.Consumer>
    </div>
  );
};

const NavigationAuth = ({ location, authUser, history, organization }) => {
  useEffect(() => {
    if (location) {
      document.title = `${organization?.settings?.appName || "Fictive Reality"} - ${capitalizeFirstLetter(location.replace("/", ""))}`;
    }
  }, [location, organization]);

  const logoUrl = organization?.settings?.appLogo || FictiveLogo;

  const handleClick = href => {
    history.push(href);
  };

  return (
    <DesktopNavWrapper>
      <StyledNavContainer>
        <img src={logoUrl} alt="App logo" />
        <NavList
          showTooltip
          handleClick={handleClick}
          authUser={authUser}
          currentPath={location}
          organization={organization}
        />
        <UserAvatarLink authUser={authUser} showTooltip />
        <SignOutButton dataCy={"mainLogout"} history={history} showTooltip />
      </StyledNavContainer>
    </DesktopNavWrapper>
  );
};

export default withRouter(Navigation);
