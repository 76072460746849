import styled from "styled-components";
import { ScaleIn, ChangeColorKeyFrame } from "../../styles/keyframes";

export const SignInContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: white;

  @media (max-width: 768px) {
    animation: ${ScaleIn} 0.6s linear;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: ${props => (props.background ? "inset 0px 0px 10px rgba(0, 0, 0, 1)" : "")};
  background-image: url(${props => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SignInWrapper = styled.div`
  background: var(--background-color-white);
  width: 35%;
  min-width: 680px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  position: relative;

  /* img {
    width: 70px;
    position: absolute;
    top: 20px;
    left: 20px;
  } */

  @media (max-width: 768px) {
    width: 100%;
    min-width: initial;
    padding: 0 40px 40px 40px;
    align-content: center;
    align-items: center;
  }
`;

export const LogoIMG = styled.img`
  width: 70px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ErrorMessage = styled.div`
  color: var(--red);
  font-weight: 500;
`;

export const SignInContent = styled.div`
  padding: 0px 80px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h1 {
    text-align: center;
  }

  svg {
    width: 20px;
    cursor: pointer;
    transition: 0.4s;
    color: var(--grey-2);
    &:hover {
      transition: 0.4s;
      transform: scale(1.1);
    }
  }

  h1 {
    padding: 0;
    color: var(--grey);
    font-size: 2em;
    font-weight: bold;
  }
  p {
    font-size: 12px;
    font-weight: 700;
    margin: 5px 0;
    span {
      color: var(--key-color-2);
      cursor: pointer;
    }
    a {
      color: var(--grey);
    }
  }

  @media (max-width: 768px) {
    text-align: center;

    svg {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 25px;
    }

    h1 {
      font-size: 1.4em;
    }
  }
`;

export const Line = styled.div`
  margin-top: 10px;
  width: 100%;
  border-bottom: 1px solid var(--grey-3);

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const SwitchModeContainer = styled.div`
  text-align: center;

  small {
    font-size: 12px;
    color: var(--grey-1);
    line-height: 20px;
  }
`;

export const ConsentContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 20px;
  background: var(--grey-5);
  margin: 15px 0;

  input {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    a {
    font-weight: 600;
    font-size: 14px;
    white-space: pre-wrap;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.4s;
    color: var(--grey-1);
    &:hover {
      transition: 0.4s;
      color: var(--key-color-2);
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 3rem;
`;

export const VerifyEmailContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  svg {
    width: 100px;
    color: var(--grey);
    animation: ${ChangeColorKeyFrame} 8s infinite;
    animation-delay: 2s;
  }
`;

export const BannerContainer = styled.div`
  padding: 15px;
  border-radius: 20px;
  background: var(--grey-5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: left;
  margin-bottom: 30px;

  h5 {
    line-height: 1.4;
    font-weight: 600;
  }

  a {
    display: inline-block;
    background: var(--grey-1);
    color: white;
    font-weight: bold;
    margin-top: 10px;
    border-radius: 20px;
    padding: 6px 20px;
    font-size: 14px;
  }
`;
