import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Navigation from "../components/Navigation";
import { withAuthentication } from "../components/Session";
import { AppContainer, PageWrapper, NotFoundContainer } from "./style";
import DataStoreContext from "../components/DataStore/DataStoreContext";
import DataStore from "../components/DataStore";
import { Bug } from "@styled-icons/boxicons-regular/Bug";
import * as Sentry from "@sentry/react";
import Loading from "../components/Loading/index";
import LogRoutes from "../components/LogRoutes";
import Organization from "../screens/Organization";

const ScenarioPage = lazy(() => import("../screens/Scenario"));
const ScenariosList = lazy(() => import("../screens/ScenariosList"));
const SessionPage = lazy(() => import("../screens/Session"));
// const SharedSessionPage = lazy(() => import("../screens/Session"));
const SessionsList = lazy(() => import("../screens/SessionsList"));
const HomePage = lazy(() => import("../screens/Home"));
const Admin = lazy(() => import("../screens/Admin"));
const Help = lazy(() => import("../screens/Help"));
const DialogueApp = lazy(() => import("../screens/DialogueApp"));
const UserProfile = lazy(() => import("../screens/UserProfile"));
const SignInPage = lazy(() => import("../screens/SignIn"));
const PasswordForgetPage = lazy(() => import("../screens/PasswordForget"));

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

const App = ({ authUser }) => {
  return (
    <AppContainer>
      <PageWrapper>
        <Suspense fallback={<Loading containerHeight="100dvh" />}>
          <Switch>
            <SentryRoute
              path={`${ROUTES.DIALOGUE_APP}/:id`}
              render={props => <DialogueApp authUser={authUser} match={props.match} />}
            />
            <Route>
              <DataStoreContext>
                <Navigation />
                {authUser && <DataStore data-cy="adminFilter" />}
                <Switch>
                  <SentryRoute path={`${ROUTES.SCENARIOS}/scenario/:id`} component={ScenarioPage} />
                  <SentryRoute exact path={ROUTES.SCENARIOS} component={ScenariosList} />
                  {/* <SentryRoute path={ROUTES.SHARED_SESSION} component={SharedSessionPage} /> */}
                  <SentryRoute path={`${ROUTES.SESSIONS}/:id`} component={SessionPage} />
                  <SentryRoute exact path={ROUTES.SESSIONS} component={SessionsList} />
                  <SentryRoute exact path={ROUTES.HOME} component={HomePage} />
                  <SentryRoute exact path={ROUTES.ADMIN} component={Admin} />
                  <SentryRoute path={`${ROUTES.ADMIN}/scenario-editor`} component={Admin} />
                  <SentryRoute path={ROUTES.ORG_SETTINGS} component={Organization} />
                  <SentryRoute path={ROUTES.HELP} component={Help} />
                  <SentryRoute path={ROUTES.USER_PROFILE} component={UserProfile} />
                  <SentryRoute exact path="/">
                    <Redirect
                      from="/"
                      to={window.location.search !== "" ? ROUTES.HOME + "/" + window.location.search : ROUTES.HOME}
                    />
                  </SentryRoute>
                  <SentryRoute
                    path={ROUTES.SIGN_IN}
                    render={() => <SignInPage key={"sign-in"} authUser={authUser} signUp={false} />}
                  />
                  <SentryRoute
                    path={ROUTES.SIGN_UP}
                    render={() => <SignInPage key={"sign-up"} authUser={authUser} signUp={true} />}
                  />
                  <SentryRoute path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                  <SentryRoute path="*" component={NotFoundComponent} />
                </Switch>
              </DataStoreContext>
            </Route>
          </Switch>
        </Suspense>
      </PageWrapper>
    </AppContainer>
  );
};

// Did this just to ensure router is known by withAuthentication. Would prefer a
// cleaner and more consistent way of handling all these Higher Order Components
const RoutedApp = () => {
  const Wrapped = withAuthentication(App);
  return (
    <Router>
      <LogRoutes>
        <Wrapped />
      </LogRoutes>
    </Router>
  );
};

export default RoutedApp;

const NotFoundComponent = () => {
  return (
    <NotFoundContainer>
      <Bug />
      <h1 style={{ padding: "0" }}>404</h1>
      <h2>Page not found</h2>

      <h5>
        The page you are looking for doesn't exist.
        <br /> Go back or select a new direction from the navigation menu.
      </h5>
    </NotFoundContainer>
  );
};
