import React, { useContext } from "react";
import { AdminContainer, AdminContentWrapper } from "../Admin/style";
import { DetailedPageTitle, AdminNavigation } from "../Admin/AdminHeader";
import { DataStoreContext } from "../../components/DataStore/DataStoreContext";
import Footer from "../../components/Footer";
import { compose } from "../../helpFunctions/general";
import { getUserType, checkManager } from "../../helpFunctions/auth";
import { withFirebase } from "../../components/Firebase";
import { withAuthorization } from "../../components/Session";
import SettingsEditor from "../../components/SettingsEditor";
import { settings as defaultSettings } from "../../constants/defaults";

const Organization = props => {
  const { state, dispatch } = useContext(DataStoreContext);

  const updateOrganization = newSettings => {
    dispatch({ type: "SET_ORGANIZATION", organization: { ...state.organization, settings: newSettings } });
    props.firebase.getOrganization(state.selectedOrg).update({
      settings: newSettings,
    });
  };

  return (
    state.selectedOrg && (
      <AdminContainer>
        <AdminContentWrapper>
          <DetailedPageTitle
            title={"Admin"}
            organization={state.organization || state.selectedOrg}
            userType={getUserType(props.authUser?.claims, state.selectedOrg)}
          />
          <AdminNavigation />
          <SettingsEditor
            settingsObject={state.organization?.settings}
            // Org settings inherit from global defaults. Doesn't make much sense to inherit from user here.
            defaultSettings={defaultSettings}
            settingKeys={[
              "sendActivityEmail",
              "debugMode",
              "alwaysShowHelp",
              "dataRetentionDays",
              "appLogo",
              "appName",
              "lipsyncStrength",
              "freeMovement",
              "openMic",
              "graphicsQuality",
              "backgroundVolume",
              "speechVolume",
              "uiVolume",
              "hideIngameUI",
              "showSubtitles",
              "chatTimeout",
              "scenarioDocumentsFeature",
              "availableLocales",
              "availableVoiceProviders",
              "availableEnvironments",
              "availableRatings",
              "availableAgentProviders",
              "startInMode",
              "ephemeralSessions",
              "sessionTimeoutSeconds",
              "conversationIdleTimeSeconds",
              "agentTemperature",
              "agentMaxResponseTokens",
              "agentRolePrefixMode",
              "agentUserCanInterrupt",
              "agentSendFillerAudio",
              "agentTrackSentiment",
              "transcriberSegmentationSilenceTimeoutMs",
              "transcriberInitialSilenceTimeoutMs",
            ]}
            handleUpdate={updateOrganization}
          >
            <p>
              To change a preference, click to edit it and select its value. It will override default preferences or
              user preferences (but may be overriden by scenario specific preferences). Remove the preference if you no
              longer want to override.
            </p>
            <p>
              <strong>Warning</strong> these are advanced settings that may create unexpected behavior if not used
              correctly.
            </p>
          </SettingsEditor>
        </AdminContentWrapper>
        <Footer />
      </AdminContainer>
    )
  );
};

const condition = (authUser, state) => checkManager(authUser?.claims, state.selectedOrg);

export default compose(withAuthorization(condition), withFirebase)(Organization);
