import * as Sentry from "@sentry/react";
import { setWith, cloneDeep, mergeWith, isObject, isNil, omitBy } from "lodash";

/**
 * Creates a slug / identifier from a string, that is a valid key in Firebase.
 * @param {string} input input string
 * @returns {string} a slug
 */
export const slugifyKey = function (input) {
  let slug = input
    .trim()
    .replace(/[_\s]+/g, "-")
    .replace(/[|<>"?*:\\.$[\]#/@]/g, "")
    // Don't allow ids that are only numbers, they will mess upp Firebase by being interpreted as lists not objects
    .replace(/^(\d+)$/g, "no$1")
    .toLowerCase();
  return slug || "-";
};

export const titleifySlug = function (input) {
  // Replace file ending
  let title = input.replace(/\.[^/.]{1,}$/, "");

  // Replace hyphens and underscores with spaces
  title = title.replace(/[-_]/g, " ");

  // Split the title into words, capitalize the first letter of each word, and join them back together
  title = title
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return title;
};

export const checkForUrlAndGenerateId = input => {
  let tempInput = input.trim();
  let isUrl = tempInput.replace("www.", "").match("^(?:https?:)?(?://)?([^/?]+)", "");
  if (isUrl) {
    return { domain: isUrl[1], id: slugifyKey(isUrl[1]) };
  } else {
    return { domain: tempInput, id: slugifyKey(tempInput) };
  }
};

// ISO-string input
export const formatDate = d => {
  if (d && d.length !== "") {
    // Converts ISOstring back to date object "weekday month day year time".
    // Then converting the date obj to a string
    let newDate = new Date(d).toString();
    // Slice to remove time from the  string and splitting on space to recieve [weekday,month,day,year]
    let dateArr = newDate.slice(0, 21).split(" ");
    return `${dateArr[2]} ${dateArr[1]} ${dateArr[3]} ${dateArr[4]}`;
  } else {
    return "-";
  }
};

export const isoDateString = d => {
  if (!d) return "-";
  return new Date(d).toISOString().substring(0, 10);
};

const isoDateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;

export const isValidISODate = d => {
  return isoDateTimeRegex.test(d);
};

export const getUserName = (users, userId) => {
  if (!userId) return "Unknown";
  return (users || []).find(user => user.id === userId)?.displayName || "Unknown";
};

export const startSpan = ({ spanRef, op, description }) => {
  if (spanRef.current) return; // SpanRef contains span already
  const transaction = Sentry.getCurrentHub().getScope().getTransaction();
  if (transaction) {
    // console.log(`Span ${description} started at`, (Date.now() - startTime)/1000);
    spanRef.current = transaction.startChild({ op, description });
  } else {
    console.log(`No current transaction started when span ${description} called, might have been auto-closed already`);
    spanRef.current = null;
  }
};

export const endSpan = (spanRef, ok = true) => {
  if (!spanRef.current) {
    console.log(`No span started when called to end`);
  } else {
    spanRef.current.finish();
    spanRef.current.setStatus(ok ? "ok" : "unknown_error");
    console.log(
      `Span ${spanRef.current.description} finished after ${(
        spanRef.current.endTimestamp - spanRef.current.startTimestamp
      ).toFixed(2)}s`
    );
    spanRef.current = null;
  }
};

export const getBackendUrl = (service, path, protocol = "https") => {
  let serviceUrl;
  const local = process.env.REACT_APP_LOCAL_BACKEND;
  if (protocol === "https") {
    serviceUrl = `https://${process.env.REACT_APP_PROJECT_ID}.engine.fictivereality.com/${service}`;
    if (local) {
      // Note, when we host local backends we probably aren't using a reverse proxy
      // That means each service needs is own port. We currently assume 7001 for convai_vocode and 7000 for the rest
      serviceUrl = `http://localhost:${service === "convai_vocode" ? 7001 : 7000}`;
    }
  } else if (protocol === "wss") {
    serviceUrl = `wss://${process.env.REACT_APP_PROJECT_ID}.engine.fictivereality.com/ws/${service}`;
    if (local) {
      serviceUrl = `ws://localhost:${service === "convai_vocode" ? 7001 : 7000}`;
    }
  } else {
    return null;
  }

  if (!path) {
    return serviceUrl;
  } else if (path.startsWith("?")) {
    return serviceUrl + path;
  } else {
    return serviceUrl + "/" + path;
  }
};

export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    arg => arg
  );

/**
 * For a path a/b/?c the search will be empty so we have to fallback to checking if its in the path
 * @param {*} locationObject
 * @returns
 */
export const getQueryArgs = locationObject => {
  if (locationObject?.search) {
    return new URLSearchParams(locationObject.search);
  } else if (locationObject?.pathname?.includes("?")) {
    return new URLSearchParams(locationObject.pathname.split("?")[1]);
  } else {
    return null;
  }
};

export const cloneAndSet = (obj, keyPath, value) => {
  let newObj = { ...obj };
  setWith(newObj, keyPath, value, Object);
  return newObj;
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const mergeRules = (a, b) => {
  if (isNil(b)) {
    return a;
  } else if (isObject(a) && isObject(b)) {
    a = mergeWith(a, b, (c, d) => (isNil(d) ? c : d));
    return a;
  } else {
    return undefined; // Default merge strategy, e.g. right overwrites left
  }
};

export const mergeSettings = settingsArray => {
  if (!settingsArray) return {};
  let merged = cloneDeep(settingsArray[0] || {});
  for (let s of settingsArray.slice(1)) {
    // Merge each setting in turn into the merged object
    mergeWith(merged, s, mergeRules);
  }
  // Clean merge from possible null values
  merged = omitBy(merged, isNil);
  return merged;
};

export const numberOrNull = value => {
  let num = Number(value);
  return isNaN(num) ? null : num;
};

/**
 * This will remove all tags from the html that are not in the allowedTags array.
 * NOTE this is not a secure way to sanitize HTML, and that should be done on the server.
 * This is just to remove certain tags before rendering.
 *
 * @param {*} html
 * @param {*} allowedTags
 * @returns
 */
export const whitelistedHtml = (html, allowedTags) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  function walk(node) {
    const children = Array.from(node.childNodes);
    for (const child of children) {
      if (child.nodeType === Node.ELEMENT_NODE) {
        if (!allowedTags.includes(child.tagName.toLowerCase())) {
          child.remove();
        } else {
          walk(child);
        }
      }
    }
  }

  walk(doc.body);

  return { __html: doc.body.innerHTML };
};

const VALID_ID_CHARS = "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

/**
 * We try to repair a firebase ID so that it's either valid or empty.
 * @param {string} id
 */
export const repairFirebaseId = id => {
  let newId = id?.slice(0, 20) || "";
  // Check that newId only contains valid characters
  if (newId.length < 13 || newId.match(`[^${VALID_ID_CHARS}]`)) {
    // If we have less than 13 chars or invalid characters, we can't repair it
    newId = "";
  } else if (newId.length < 20) {
    // Try to repair the ID by mimicing how Firebase generates the first part of the ID
    // (from a timestamp 60 days ago, e.g. when the invite could probably have been created)
    // See https://gist.github.com/mikelehen/3596a30bd69384624c11 for ID generation details
    var now = new Date().getTime();

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = VALID_ID_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    var guessedId = timeStampChars.join("");

    newId = guessedId.slice(0, 20 - newId.length) + newId;
  }
  if (!newId) {
    console.error(`Could not repair ID ${id}`);
  } else if (newId !== id) {
    console.log(`Repaired ID ${id} to ${newId}`);
  }
  return newId;
};
